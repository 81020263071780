import { activateAccordionSection, handleAccordionTriggerClick, prepareAccordionSection } from '../modules/accordion';
import { isDeviceWidthBelow } from '../utils/device';
import { throttle } from '../utils/rate-limiter';

const initBlock = ( $block ) => {
	const $accordion = $block.find( '.js-accordion' );
	const $map = $block.find( '.js-map' );
	const $mapPins = $map.find( '.js-map-pin' );

	/**
	 * Prepare accordion section.
	 */
	prepareAccordionSection( $accordion );

	/**
	 * Activate map pin.
	 *
	 * @param {jQuery} $pin
	 * @param {boolean} [state]
	 * @return {void}
	 */
	const activatePin = ( $pin, state = null ) => {
		const firstPinSelector = '.js-map-pin-icon:first-child';
		const activeClass = 'is-active';

		// Toggle map pin.
		$pin
			.find( firstPinSelector )
			.toggleClass( activeClass, !state )
			.siblings()
			.toggleClass( activeClass, state );

		// Deactivate sibling pins.
		$pin
			.siblings()
			.not( $pin )
			.find( firstPinSelector )
			.addClass( activeClass )
			.siblings()
			.removeClass( activeClass );
	};

	/**
	 * Scroll to accordion's parent.
	 */
	const scrollToAccordion = () => {
		$( 'html, body' ).animate( {
			scrollTop: $accordion.closest( '.js-content' ).offset().top - 150,
		}, 400 );
	};

	/**
	 * Handle accordion trigger click.
	 */
	$accordion.on( 'click', '.js-accordion-trigger', throttle( function( event ) {
		event.preventDefault();

		const $trigger = $( this );
		const $section = $trigger.closest( '.js-accordion-section' );

		const locationName = $section.data( 'location' );
		const $mapPin = $map.find( `[data-location="${ locationName }"]` );

		handleAccordionTriggerClick( $trigger );

		const sectionState = $section.hasClass( 'is-active' );

		activatePin( $mapPin, sectionState );
	}, 400 ) );

	/**
	 * Handle map pin click.
	 */
	$mapPins.on( 'click', function( event ) {
		event.preventDefault();

		const $pin = $( this );
		const isPinActive = $pin.find( '.js-map-pin-icon:last-child' ).hasClass( 'is-active' );

		if ( isPinActive ) {
			return;
		}

		const locationName = $pin.data( 'location' );
		const $accordionSection = $accordion.find( `[data-location="${ locationName }"]` );

		activatePin( $pin, true );

		if ( !$accordionSection.hasClass( 'is-active' ) ) {
			activateAccordionSection( $accordionSection );
		}

		if ( isDeviceWidthBelow( 767 ) ) {
			scrollToAccordion();
		}
	} );
};

app.loadBlock( initBlock, 'locations', '.js-section-locations' );
