/**
 * Prepare accordion section.
 *
 * @param {jQuery} $accordion
 * @return {void}
 */
export const prepareAccordionSection = ( $accordion ) => {
	const $accordionSections = $accordion.find( '.js-accordion-section' );

	$accordionSections.each( ( _, section ) => {
		const $section = $( section );
		const isActive = $section.hasClass( 'is-active' );

		if ( isActive ) {
			return;
		}

		const $content = $section.find( '.js-accordion-content' );

		$content.hide();
	} );
};

/**
 * Activate accordion section.
 *
 * @param {jQuery} $section
 * @return {void}
 */
export const activateAccordionSection = ( $section ) => {
	const $content = $section.find( '.js-accordion-content' );

	// Toggle current section.
	$section.toggleClass( 'is-active' );
	$content.slideToggle();

	// Hide sibling sections.
	$section
		.siblings()
		.removeClass( 'is-active' )
		.find( '.js-accordion-content' )
		.slideUp();
};

/**
 * Handle accordion trigger click.
 *
 * @param {jQuery} $trigger
 * @return {void}
 */
export const handleAccordionTriggerClick = ( $trigger ) => {
	const $section = $trigger.closest( '.js-accordion-section' );

	activateAccordionSection( $section );
};
